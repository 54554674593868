import axios from '@axios'

export default {
    namespaced: true,
    state: {  /*markets: [], */   },
    getters: {},
    mutations: {
        // SET_MARKETS(state, markets) {
        //   state.markets = markets;
        // },
    },
    actions: {
        list: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('growers', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
          // get all crops from database
          crops: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('crops',  { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        show: (cntx, { id }) => {
            return new Promise((resolve, reject) => {
                axios.get(`growers/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        create: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.post('growers', params)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },

        loanSave: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.post('loans', params)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        update: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.put(`growers/${params.id}`, params.data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        loanUpdate: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.put(`loans/${params.id}`, params.data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        imports: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.post('imports', params)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        remove: (cntx, id) => {
            return new Promise((resolve, reject) => {
                axios.delete(`growers/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        levels: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('levels', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        locations: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('locations', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        societies: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('societies', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        villages: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('villages', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        sectors: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('sectors', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        clubs: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('clubs', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        contracts: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('contracts', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        seasons: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('seasons', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        loans: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('loans',  { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

      

        packages: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('packages', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        inputs: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('inputs', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        // Other actions...
        markets({ commit }) {
            return new Promise((resolve, reject) => {
            // Replace this with your actual API call
            axios
                .get('/markets')
                .then(response => {
                // commit('SET_MARKETS', response.data.markets);
                resolve(response);
                })
                .catch(error => {
                reject(error);
                });
            });
        },
    }
}
