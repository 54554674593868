import Vue from "vue";
import Vuex from "vuex";

// Modules
import ecommerceStoreModule from "@/views/apps/e-commerce/eCommerceStoreModule";
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import apiMenu from "./api-menu";
import cromisData from "./cromis-data";
import growersStoreModule from '../views/cromis/grower/growersStoreModule';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    apiMenu,
    cromisData,
    "app-ecommerce": ecommerceStoreModule,
    'cromis-grower': growersStoreModule,
  },
  strict: process.env.DEV,
});
